<template>
  <div>
    <div id="loading" class="loading" v-if="showLoading">
      <!--<div class="loading_text">数据请求中..</div>-->
      <vue-simple-spinner />
      <div class="loading_text">请求中...</div>
    </div>

    <div class="new_header">
      <div class="title">{{ merchantName }}</div>
    </div>

    <div class="new_middle">
      <div>￥</div>
      <div><input type="text" v-model="amount" placeholder="请输入金额" id="amount" readonly class="money"/></div>
    </div>

    <div class="new_tips">请核对以上金额、收款商户等信息与交易一致:经您确认支付的款项，支付机构无法追回亦无赔偿义务。</div>

    <!--<header class="header">
      <div class="header-text">付款给 {{ merchantName }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div>
    </header>-->
    <!--<div class="plus-input">-->
      <!-- ￥字符 货币的符号-->
      <!--<div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>-->

      <!-- 手写输入框 -->
      <!--<div  class="input-c" style="width: 100%">
        <div  class="input-c-div-1" style="width: 100%">
          <input type="text" v-model="amount" placeholder="请输入金额" id="amount1" readonly/>
        </div>
      </div>-->
      <!-- 手写输入框的提示文字 -->
      <!--<div v-show="!amount" class="placeholder">请输入金额</div>-->
    <!--</div>-->
    <!--<ul class="plus-ul" >
      <li style="border-radius:10px;">
        <div class="img-div">
          <input type="radio" checked/>
          <img :src="wxImg" alt="" />
          <div class="div-text">
            支付宝支付
          </div>
        </div>
      </li>
    </ul>-->
    <!--<div class="bnt-pay">
      <div
        class="bnt-pay-text"
        style="background-color:#1678ff"
        @click="createOrder"
      >
        付款
      </div>
    </div>-->

    <!--<div v-if="isPay">加载中...</div>-->

    <div class="new_remark">{{remark}}</div>

    <div class="pay_logo">
      <img src="../../assets/images/yspay.png" class="yspay"/>
      <div>已服务百万级商家</div>
    </div>

    <div class="keyboardbox" v-if="brandFold">
      <div class="numkeyboard">
        <div class="num-area">
          <div class="row" v-for="(item,index) in numKeybordList" :key="index">
            <div :class="['item',ite===0?'z':'']" v-for="(ite,idx) in item"
                 :key="idx" @click="input({num:ite})">{{ite}}</div>
          </div>
        </div>
        <div class="btn-area">
          <div :class="['item','del']"  @click="delNumFun">
            <span class="iconfont icon-yijianshanchu">
              <img src="../../assets/icon/delete.png" style="width: 70%"/>
            </span>
          </div>
          <div :class="amount !== '' ? 'confirem item confiremActive' : 'confirem item'" @click="createOrder">
            <div>立即</div>
            <div>支付</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPayPackage, getPayOrderInfo, getCompany, unifiedOrder, getCompanyGroup } from '@/api/api'
import config from "@/config";
import CryptoJS from 'crypto-js'
import VueSimpleSpinner from 'vue-simple-spinner'
export default {
  components: { VueSimpleSpinner },
  data: function (){
    return {
      merchantName: '',  // 付款的商户默认
      appId: '',
      isPay: false,
      showLoading: false,
      avatar: require("../../assets/images/zfb.jpeg"), // 商户头像默认
      amount: '',  // 支付金额默认
      resData : {},
      wxImg: require("../../assets/images/zfb.jpeg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      mchNo: '',
      remark: '',
      brandFold: false,
      groupId: '',
      numKeybordList: [
          [1, 2, 3],
          [4, 5, 6],
          [7, 8, 9],
          [0, '.']
      ]
    }
  },

  mounted() {
     // this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
      this.appId = this.$route.params.appId
      this.groupId = this.$route.params.groupId
      this.openid = this.$route.params.channelUserId
      if(this.groupId && this.groupId !== ''){
          this.getCompanyGroupInfo(this.groupId)
      } else {
          this.getCompanyInfo(this.appId)
      }
      this.brandFold = true
      // document.getElementById("amount").focus()
  },

  methods: {
      delNumFun() {
          if (this.amount.length === 0) return
          this.amount = this.amount.substring(0, this.amount.length - 1)
      },
      input(op) {
          if(this.amount.length === 0 && op.num === '.'){
              return
          }
          if(this.amount.indexOf('.') !== -1 && op.num === '.'){
              return
          }
          if(this.amount.indexOf('.') !== -1 && this.amount.split('.')[1].length > 1){
              return
          }
        if (this.amount.length <= 6) {
            this.amount += op.num
        }
    },
    keyUpFn(ev){
        if(ev.keyCode === 13){
            this.createOrder()
        }
    },
    setPayOrderInfo(isAutoPay){
      const that = this
      getPayOrderInfo().then(res => {
        that.payOrderInfo = res

        if(isAutoPay){
          that.pay()
        }
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },

    pay: function (){
        if(this.amount === null || this.amount === '' || this.amount <= 0){
            alert('请输入支付金额！');
            return;
        }

      let that = this;
      getPayPackage(this.amount).then(res => {

        //订单创建异常
        if(res.code != '0') {
          return alert(res.msg);
        }

        if(res.data.orderState != 1 ) { //订单不是支付中，说明订单异常
          return alert(res.data.errMsg);
        }

        if (!window.AlipayJSBridge) {
          document.addEventListener('AlipayJSBridgeReady', function(){
            that.doAlipay(res.data.alipayTradeNo);
          }, false);
        }else{
          that.doAlipay(res.data.alipayTradeNo);
        }

      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },


    doAlipay(alipayTradeNo){

      const that = this

      // eslint-disable-next-line no-undef
      AlipayJSBridge.call("tradePay", {
        tradeNO: alipayTradeNo
      }, function (data) {
        if ("9000" == data.resultCode) {
          // alert('支付成功！');

          // //重定向
          if(that.payOrderInfo.returnUrl){
            location.href = that.payOrderInfo.returnUrl;
          }else{
            alert('支付成功！');
            window.AlipayJSBridge.call('closeWebview')
          }

          //‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
        // ‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
        }else if("8000" == data.resultCode || "6004" == data.resultCode){ //其他

          alert(JSON.stringify(data));
          window.AlipayJSBridge.call('closeWebview')

        }else{ ///其他异常信息， 需要取消订单
          alert('用户已取消！');
          window.AlipayJSBridge.call('closeWebview')
        }
      });
    },
    getCompanyInfo(appId){
        const that = this
        getCompany(appId).then(res => {
            that.merchantName = res.mchShortName
            that.appId = res.appId
            that.mchNo = res.mchNo
            that.remark = res.remark
        }).catch(res => {
            that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
        });
    },
  getCompanyGroupInfo(groupId){
      const that = this
      getCompanyGroup(groupId).then(res => {
          that.merchantName = res.mchShortName
          that.appId = res.appId
          that.mchNo = res.mchNo
          that.remark = res.remark
      }).catch(res => {
          that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
  },
    createOrder(){
        if(this.isPay){
            return;
        }
        if(this.amount === ''){
            return;
        }
        this.amount = parseFloat(this.amount)
        if(this.amount === null || this.amount === '' || this.amount <= 0){
            alert('请输入支付金额！');
            return;
        }
        if(this.merchantName === '' || this.appId === ''){
            alert('没有支付信息！');
            return;
        }

        this.isPay = true
        this.showLoading = true

        const timestamp = new Date().getTime();
        let min = 1000;
        let max = 9999;
        let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        let mchOrderNo = 'O' + timestamp + randomInt
        let channelExtra = {openid: this.openid}

        let params = {
            'mchNo': this.mchNo,
            'appId': this.appId,
            'mchOrderNo': mchOrderNo,
            'wayCode': 'ALI_LITE',
            'amount': parseInt(this.amount * 100),
            'currency': 'cny',
            'subject': '商品订单',
            'body': '商品订单',
            'notifyUrl': 'https://api.merchant.sybpay.cn/api/anon/paytestNotify/payOrder',
            'reqTime': timestamp,
            'version': '1.0',
            'signType': 'MD5',
            'openType': 'self',
            'channelExtra': JSON.stringify(channelExtra)
        }

        const sortedObj = Object.keys(params).sort().reduce((acc, key) => {
            acc[key] = params[key];
            return acc;
        }, {});

        let tempArr = []
        for (let key in sortedObj) {
            let tempStr = key + '=' + params[key]
            tempArr.push(tempStr)
        }

        let singStr = tempArr.join('&')

        singStr = singStr + '&key=123456'

        const md5Hash = CryptoJS.MD5(singStr).toString();

        params.sign = md5Hash

        const that = this
        unifiedOrder(params).then(res => {
            if(res.errCode && res.errCode !== ''){
                that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.errMsg}})
                return
            }
            that.isPay = false
            that.showLoading = false
            // window.open(res.payData)
            // window.location.href = res.payData

            var jsonObject = JSON.parse(res.payData)
            if (!window.AlipayJSBridge) {
                document.addEventListener('AlipayJSBridgeReady', function(){
                    that.doAlipay(jsonObject.tradeNO);
                }, false);
            }else{
                that.doAlipay(jsonObject.tradeNO);
            }
        }).catch(res => {
            that.isPay = false
            that.showLoading = false
            that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
        });
    }
  }


}
</script>
<style scoped lang="less">
  @baseWidth: 1080px;
 .width-auto(@w){
   width: (@w/@baseWidth) * 100vw
 }
 .height-auto(@w){
   height: (@w/@baseWidth) * 100vw
 }
.font-size-auto(@w){
  font-size: (@w/@baseWidth) * 100vw
}
.margin-top-auto(@w){
  margin-top: (@w/@baseWidth) * 100vw
}
.margin-bottom-auto(@w){
  margin-bottom: (@w/@baseWidth) * 100vw
}
.margin-left-auto(@w){
  margin-left: (@w/@baseWidth) * 100vw
}
.margin-right-auto(@w){
  margin-right: (@w/@baseWidth) * 100vw
}
.padding-auto(@w){
  padding: (@w/@baseWidth) * 100vw
}
.radius-auto(@w){
  border-radius: (@w/@baseWidth) * 100vw
}
.bottom-auto(@w){
  bottom: (@w/@baseWidth) * 100vw
}
 @import './pay.css';
.keyboardbox{
  background-color: #f0f0f0;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
}
.keyboardbox .numkeyboard {
  .height-auto(800);
  display: flex;
  background-color: #ebedf0;
}
 .keyboardbox .numkeyboard .btn-area{
   /*width: 5rem;*/
   .width-auto(350);
   .height-auto(800);
   display: flex;
   flex-direction: column;
   margin-right: 0.2rem;
 }
 .keyboardbox .numkeyboard .btn-area .item{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-grow: 1;
 }
 .keyboardbox .numkeyboard .btn-area .del{
   background-color: #fff;
   color: #000;
   .height-auto(180);
   .radius-auto(20);
   .margin-bottom-auto(20);
   /*border-radius: 0.5rem;*/
   /*margin-bottom: 0.32rem;*/
 }
 .keyboardbox .numkeyboard .btn-area .del:active{
   background-color: #f1f3f5;
 }
 .keyboardbox .numkeyboard .btn-area .confirem {
   background-color: #b4ccec;
   color: #FFFFFF;
   .height-auto(600);
   .radius-auto(20);
   .font-size-auto(70);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   .margin-bottom-auto(20);
   /*margin-bottom: 0.35rem;*/
 }
 .keyboardbox .numkeyboard .btn-area .confiremActive {
   background-color: #0570db;
 }
 .keyboardbox .numkeyboard .num-area{
   flex-grow: 1;
   display: flex;
   flex-wrap: wrap;
 }
 .keyboardbox .numkeyboard .num-area .row{
   width: 98.5%;
   .height-auto(200);
   display: flex;
 }
 .keyboardbox .numkeyboard .num-area .row .item{
   flex-grow: 1;
   .height-auto(180);
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #FFFFFF;
   border-right: 1px solid #ebedf0;
   width: 32.5%;
   .font-size-auto(100);
   font-weight: 600;
   .radius-auto(20);
   .margin-left-auto(20);
 }
 .keyboardbox .numkeyboard .num-area .row .item:active{
   background-color: #ebedf0;
 }
 .keyboardbox .numkeyboard .num-area .row .item:disabled{
   background: #FFFFFF;
   color: #B9B9B9;
 }
 .keyboardbox .numkeyboard .num-area .row .z{
   flex-grow: 2;
   width: 66.66%;
 }
.new_header{
  .width-auto(1080);
  .height-auto(200);
  background: url("../../assets/images/background.png") center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new_header .title{
  .font-size-auto(65)
}
.new_middle{
  width: 100%;
  background-color: #FFFFFF;
  .height-auto(250);
  border-radius: 10px;
  display: flex;
  .font-size-auto(110);
  align-items: center;
  margin-top: 5px;
  padding-left: 15px;
}
.new_middle .money{
  .height-auto(200);
  width: 100%;
  .font-size-auto(100);
  border: unset;
  margin-left: 20px;
}
.new_tips{
  color: #909399;
  .font-size-auto(40);
  text-align: left;
  .padding-auto(50)
}
.new_remark{
  text-align: left;
  .font-size-auto(50);
  .padding-auto(50)
}
.pay_logo{
  .height-auto(120);
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  .bottom-auto(830);
  width: 100%;
  .font-size-auto(40);
}
.yspay{
  .width-auto(350);
  .margin-right-auto(20);
}
.loading{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading_text{
  .font-size-auto(30);
  .padding-auto(30);
  .radius-auto(20);
}
</style>